import { StringParam, useQueryParam } from 'use-query-params';
import * as React from 'react';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { PageLayout } from '@/components/page-layout';
import { editorRoute, Pages } from '@/webapi/pages';
import { openLinkInNewTab } from '@/utils/browser';
import { useInspirationsPage } from '@/features/inspirations/use-inspirations-page';
import { ShortInspirationTile } from '@/features/inspirations/short-inspiration-tile';
import { HSpace, VSpace } from '@/components/spacing';
import { useAutoScroll } from '@/features/inspirations/use-auto-scroll';
import { InspirationOrigin } from '@/features/inspirations/listing-page';

export function InspirationPostPage() {
  const [url] = useQueryParam(`url`, StringParam);
  const [id] = useQueryParam(`id`, StringParam);
  const [backTo] = useQueryParam(`bt`, StringParam);
  const [height, setHeight] = useState(`100vh`);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const { recs, status, markPostAsSeen } = useInspirationsPage(id);
  const iframeRef = useRef(null);

  const {
    scrollRef,
    showNext,
    onNextHover,
    onNextExitHover,
    onPrevExitHover,
    onPrevHover,
    showPrev,
  } = useAutoScroll();

  useEffect(() => {
    const handler = (ev) => {
      if (ev?.data?.type === `iframeHeightChange`) {
        setHeight(`${ev.data.height + 20}px`);
      } else if (ev?.data?.type === `newExperience`) {
        openLinkInNewTab(editorRoute(), ``);
      } else if (ev?.data?.type === `markAsSeen`) {
        markPostAsSeen();
      } else if (ev?.data?.type === `overlayOpen`) {
        setIsOverlayOpen(true);
      } else if (ev?.data?.type === `overlayClose`) {
        setIsOverlayOpen(false);
      }
    };
    window.addEventListener(`message`, handler);
    return () => {
      window.removeEventListener(`message`, handler);
    };
  }, []);

  useEffect(() => {
    if (status) {
      notifySeen(status.seen);
      setTimeout(() => notifySeen(status.seen), 1000);
      setTimeout(() => notifySeen(status.seen), 2000);
    }
  }, [status]);

  const notifySeen = (seen: boolean) => {
    if (!iframeRef?.current?.contentWindow?.postMessage) {
      setTimeout(() => notifySeen(seen), 500);
    } else {
      iframeRef?.current?.contentWindow?.postMessage(
        { type: `inspirationSeen`, value: seen },
        `*`,
      );
    }
  };

  const onBack = () => {
    if (backTo === `experiences`) {
      navigate(`${Pages.DASHBOARD}?pg=LIVE&scrollTo=inspirations`);
    } else {
      navigate(`${Pages.DASHBOARD}?pg=INSPIRATIONS`);
    }
  };

  return (
    <PageLayout hideBackButton heading="">
      <Text onClick={onBack}>{`<`} Back to all Inspirations</Text>
      <Iframe
        ref={iframeRef}
        title="inspiration-post"
        src={atob(url).replace(`http:`, `https:`)}
        style={{
          height: isOverlayOpen ? `100vh` : height,
          position: isOverlayOpen ? `fixed` : `relative`,
          inset: 0,
        }}
      />
      {!!recs && (recs?.inspirations?.length || 0) > 0 && !isOverlayOpen && (
        <>
          <Title>More Inspirations</Title>
          <VSpace value={5} />
          <SliderWrapper>
            <ScrollButton
              className="left"
              isShown={showPrev}
              onMouseEnter={onPrevHover}
              onMouseLeave={onPrevExitHover}
            >
              <FaChevronLeft size={8} />
            </ScrollButton>
            <Slider ref={scrollRef}>
              {recs &&
                recs?.inspirations.map((rec) => (
                  <ShortInspirationTile
                    id={rec.id}
                    title={rec.title}
                    image={rec.coverImage}
                    description={rec.description}
                    uplift={rec.uplift}
                    url={rec.url}
                    origin={backTo as InspirationOrigin}
                  />
                ))}
              {(recs?.inspirations?.length || 0) > 2 && <HSpace value={15} />}
            </Slider>
            <ScrollButton
              className="right"
              isShown={showNext}
              onMouseEnter={onNextHover}
              onMouseLeave={onNextExitHover}
            >
              <FaChevronRight size={8} />
            </ScrollButton>
          </SliderWrapper>
          <VSpace value={10} />
        </>
      )}
    </PageLayout>
  );
}

const Iframe = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;

const Text = styled.span`
  font-family: 'Eesti', serif;
  font-size: 1.3rem;
  font-weight: normal;
  color: #999999;
  cursor: pointer;
  user-select: none;

  &&:hover {
    opacity: 0.8;
  }

  &&:active {
    opacity: 0.6;
  }
`;

const Title = styled.span`
  margin: 0;
  font-size: 2.8rem;
  font-weight: bold;
  font-family: 'Eesti', serif;
`;

const SliderWrapper = styled.div`
  position: relative;
  max-width: 100vw;
`;

const Slider = styled.div`
  padding: 10px 15rem 10px 15rem;
  margin: -10px;
  display: grid;
  grid-auto-columns: 50rem;
  grid-auto-flow: column;
  grid-gap: 4rem;
  overflow-x: scroll;
`;

const ScrollButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: calc(50% - 1.5rem);
  bottom: 0;

  &&.right {
    cursor: e-resize;
    right: 2rem;
  }

  &&.left {
    cursor: w-resize;
    left: 2rem;
  }

  background: #e5e5e5;
  border-radius: 50%;
  z-index: 100;

  height: 3rem;
  width: 3rem;

  box-shadow: 0 6px 7px 0 rgba(181, 193, 204, 0.7);

  :hover {
    box-shadow: 0 6px 7px 0 rgba(181, 193, 204, 1);
    opacity: 0.6;
    background: rgba(222, 222, 222, 0.1);
    backdrop-filter: blur(4px);
  }

  opacity: ${(p) => (p.isShown ? `1` : `0`)};
  pointer-events: ${(p) => (p.isShown ? `auto` : `none`)};
  transition: all 0.2s linear;
`;

import { CachePolicies, useFetch } from 'use-http';
import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { routes } from '@/webapi/routes';
import {
  InspirationResponse,
  InspirationStatusResponse,
} from '@/features/inspirations/use-inspirations-listing';
import { useDetachedState } from '@/components/hooks/use-detached-state';

export function useInspirationsPage(postId: string) {
  const { post, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: false,
    },
  }));
  const [recs, setRecs] = useState<InspirationResponse>(undefined);
  const [status, setStatus, statusRef] = useDetachedState<
    InspirationStatusResponse | undefined
  >(undefined);

  const recommend = async () => {
    const req = { inspirationId: postId, count: 8 };
    const resp = await post(routes.recommendInspirations(), req);
    return resp as InspirationResponse;
  };

  const getStatus = async () => {
    const req = { inspirationId: postId };
    const resp = await post(routes.getInspirationStatus(), req);
    return resp as InspirationStatusResponse;
  };

  const markPostAsSeen = async () => {
    const req = { inspirationId: postId };
    const resp = await post(routes.markInspirationAsSeen(), req);
    if (statusRef.current?.seen) {
      setStatus({ seen: false });
    } else {
      setStatus({ seen: true });
    }
    return resp;
  };

  useAsyncEffect(async () => {
    const status = await getStatus();
    if (!status?.seen) {
      setStatus({ seen: false });
    } else {
      setStatus(status);
    }

    const recs = await recommend();
    setRecs(recs);
  }, [postId]);

  return {
    loading,
    markPostAsSeen,
    recommend,
    recs,
    status,
  };
}
